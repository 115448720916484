<template>
  <div class="content-box">
    <div class="content-header">

      <el-button type="plain" class="cotent-btn-big" size="medium"  icon="el-icon-plus" @click="handleEdit">新增app升级版本</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" max-height="700" :header-cell-style="headerCellStyle" border
        v-loading="loading" :cell-style="cellStyle">

        <el-table-column prop="id" label="主键"  width="60">
        </el-table-column>

        <el-table-column prop="appVersion" label="app版本"  width="140">
        </el-table-column>

        <el-table-column prop="downloadUrl" label="下载地址" >
        </el-table-column>
      
        <el-table-column  label="强制升级" width="80">
          <template slot-scope="scope">
            <div class="device-online" v-if="scope.row.forcedState == 1">强制</div>
            <div class="device-offline" v-else>不强制</div>
          </template>
        </el-table-column>
        <el-table-column  label="最新版本" width="80">
          <template slot-scope="scope">
            <div class="device-online" v-if="scope.row.latestVersion == 1">是</div>
            <div class="device-offline" v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column prop="deleteState" label="禁用状态" width="80">
          <template slot-scope="scope">
            <div class="device-online" v-if="scope.row.deleteState == 1">禁用</div>
            <div class="device-offline" v-else>启用</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>

      <el-dialog title="新增/编辑 app版本" :visible.sync="dialogVisible" width="460px" :before-close="dialogClose">
        <div class="edit-box">
          <el-form label-position="left" label-width="100px" :model="formData" ref="validateForm" :rules="rules">
            <el-form-item label="app版本:" prop="appVersion">
              <el-input v-model="formData.appVersion" class="dialogInput" placeholder="app版本" maxlength="35">
              </el-input>
            </el-form-item>
   
          
         
        <el-form-item label="APP包上传:" prop="downloadUrl">
          <div class="avatar-uploader avatar-uploader-box">
            <el-upload
            action="#"
            :auto-upload="false"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-change="singleFileChange"
          :on-progress="getTmp_secret_keys"
              :on-success="onSuccess"
            >
            <el-button type="text" size="small" icon="el-icon-upload">点击上传</el-button>
          </el-upload>
          <el-progress v-if="showProgress" :percentage="percentage"></el-progress>
          </div>
        </el-form-item>

            <el-form-item label="文件包地址:" prop="icon">
              <el-input v-model="formData.downloadUrl"  class="dialogInput" placeholder="文件地址" maxlength="135">
              </el-input>
            </el-form-item>
            <el-form-item label="更新说明:" prop="icon">
              <el-input type="textarea"
              :rows="2" v-model="formData.updateContent"  class="dialogInput" placeholder="更新说明" maxlength="500">
              </el-input>
            </el-form-item>
            <el-form-item label="是否强制升级:" prop="forcedState">
              <el-radio-group v-model="formData.forcedState">
                <el-radio :label="0">不强制</el-radio>
                <el-radio :label="1">强制升级</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否最新:" prop="latestVersion">
              <el-radio-group v-model="formData.latestVersion">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否禁用:" prop="deleteState">
              <el-radio-group v-model="formData.deleteState">
                <el-radio :label="0">启用</el-radio>
                <el-radio :label="1">禁用</el-radio>
              </el-radio-group>
  
            </el-form-item>
         
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {  Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import config from "@/config/default";

import {
  list,
  saveApp,

} from "@/api/appUpgrade";



// 工具
import upload from "@/utils/upload";
import deepCopy from "@/utils/deepCopy";

const initFormData = {
  title: "",
  subtitle: "",
  icon: "",

};

@Component
export default class appList extends Mixins(loading, tablePagination) {

  showProgress=false;
  percentage=0;
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  formData = {
    "id": 0,
    "appVersion": "",
    "publishTime": "",
    "updateTime": "",
    "downloadUrl": "",
    updateContent: ""
  };
  uploadFile=null;
  appList = [];
  rules = {
    title: [
      { required: true, message: 'app标题', trigger: 'blur' }
    ],
    subTitle: [
      { required: true, message: '子标题', trigger: 'blur' }
    ]
  };
  mounted() {
    this.getData();
  }




  getData() {
    this.showLoading();
    list({
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then(res => {
      this.hideLoading();
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
    }).finally(() => {
      this.hideLoading();
    });
  }
   // 上传成功
   onSuccess(res, file) {
    this.formData.downloadUrl = this.$uploadImgUrl + "/" + res.data.url;
  }
  beforeAvatarUpload (file) {
 
      this.uploadFile = file
    }
    // 上传文件
    getTmp_secret_keys() {
      console.log("正在上传");
      let files = upload(this.uploadFile,res => {
        console.log(res,'上传完成')

      })
    }
    singleFileChange(file){
      console.log("正在上传"+file);
      let files = upload(file,p=>{
        this.showProgress=true;
        this.percentage=  p.percent*100;

      },(res,name) => {
        this.formData.downloadUrl=res;
        console.log(res,'上传完成')
        if(!this.formData.appVersion && name){
           this.formData.appVersion = name.replace("SmartHealth_release_","")
        }
      })

    }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }
  getGroupName(groupType) {
    switch (groupType) {
      case 1:
        return "智能体测系统";
      case 2:
        return "智能调理系统";
      case 3:
        return "其他功能";
      default:
        return "-";
    }
  }



  // 头部输入
  changeValue(val, type) {
    this[type] = val;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }
  handleEdit(row) {
    let { id, appVersion, downloadUrl, forcedState, deleteState, latestVersion, updateContent } = row;
    this.formData = {
      id, appVersion, downloadUrl, forcedState, deleteState, latestVersion, updateContent
    };
    this.dialogVisible = true;
  }


  dialogClose() {
    this.formData = deepCopy(initFormData);
    this.$refs['validateForm'].resetFields();
    this.dialogVisible = false;
  }
  dialogConfirm() {
    this.$refs['validateForm'].validate((valid) => {
      if (valid) {
        this.showLoading();
       
        saveApp(this.formData).then(res => {
          this.dialogClose();
          this.resetPages();
          this.getData();
        }).finally(() => {
          this.hideLoading();
        });
      } else {
        console.log('error submit!!');
        return false;
      }
    })
  }


}
</script>



<style lang="scss" scoped src="./style.scss">
</style>