import { service } from '@/ajax/request'
import { AxiosPromise } from 'axios'

import COS from 'cos-js-sdk-v5'


export const list = (params: any) => (
  service({
    url: '/app/upgrade/list',
    method: 'GET',
    params,
    type: 'form'
  })
)




export const saveApp = (params: any) => (
  service({
    url: '/app/upgrade/save',
    method: 'POST',
    params,
    type: 'form'
  })
)
// 企业/个人 入驻
export const getTmp_secret_key =() =>(
  service({
    url: '/app/upgrade/temp',
      method: 'GET'
  })
)