import COS from 'cos-js-sdk-v5';

import {getTmp_secret_key,list } from "@/api/appUpgrade";
import { Message } from 'element-ui'
export default function upload(file,progress,callback) {
    let fileUrl = ''
    getTmp_secret_key().then(response => { // 后台接口返回 密钥相关信息
    console.log(response);
    const data = response
    let uploadFileName = Date.parse(new Date())
    const name =  file.name.substring(0,file.name.lastIndexOf("."))
    console.log("文件名称："+name);
    if(name){
        uploadFileName = name;
    }
    const type = file.name.substring(file.name.lastIndexOf(".")+1)
    const copyFile = new File([file.raw], `${name}.${type}`)
    var cos = new COS({
        getAuthorization: function(options, callback) {
          callback({
            TmpSecretId: data.credentials.tmpSecretId,
            TmpSecretKey: data.credentials.tmpSecretKey,
            XCosSecurityToken: data.credentials.sessionToken,
            StartTime: data.startTime,
            ExpiredTime:data.expiredTime,
            expiration:data.expiration,
            requestId: data.requestId
          })
        }
      })
      cos.putObject(
        {
          Bucket: 'yuyi-1301954635', // 存储桶名称
          Region: 'ap-guangzhou', // 地区
          Key: '/apk/' + uploadFileName + '.apk', // 图片名称
          StorageClass: 'STANDARD',
          Body: copyFile, // 上传文件对象
          onHashProgress: function (progressData) {
            console.log ('校验中', JSON.stringify (progressData));
          },
           onProgress: function (progressData) {
            progress(progressData);
            console.log ('上传中', JSON.stringify (progressData));
           },
        },
       function (err, data) {
         console.log('999',err,data)
        if (err) {
            Message({message:'文件上传失败,请重新上传',type: 'error'})
        } else {
            let fileUrl = 'https://' + data.Location
            callback(fileUrl,uploadFileName)
        }
        }
      )
   })
}


